import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import Setting from './Settings';
import Password from './Password';
import { Sidebar } from '../Common/Sidebar/index';
import { Header } from '../Common/Header';
import { PageLoader } from '../Common/PageLoader';
import { ToastContainer } from '../Common/Toast/ToastContainer';
import '../Common/index.css';


class Index extends React.Component {

    render() {

        if (!this.props.config.config) {
            return <PageLoader />
        }

        return (
            <div style={{
                display: 'flex',
                flex: 1
            }}>
                <Sidebar
                    {...this.props}
                />
                <ToastContainer />
                <div style={{
                    flex: 1,
                    backgroundColor: 'var(--background-color)',
                }}>
                    <Header {...this.props} />
                    <Switch>
                        <Route path="/app/home" component={Home} />
                        <Route path="/app/setting" component={Setting} />
                        <Route path="/app/password" component={Password} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.config
    }
}, null)(Index);