import { axios } from "../../../../Model/Network/Config/Axios";



class Model {

    deleteOrg = (id, cb) => {
        axios.delete(`organization/delete/${id}`)
            .then(cb)
    }

}

export default Model;

