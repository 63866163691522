import React from 'react';


class FloatInput extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    getValue = () => {
        return this.state.value
    }

    setValue = (value) => {
        this.setState({
            value
        })
    }


    render() {
        return (
            <div className="input">
                <p style={{
                    fontSize: 12,
                    color: 'var(--title-color)',
                    fontWeight: 600,
                    marginTop: 15
                }}>
                    {this.props.title}:
                </p>

                <div style={{
                    height: 40,
                    border: '1px solid var(--border-color)',
                    marginTop: 8,
                    width: '100%'
                }}>
                    <input type={this.props.type} style={{
                        width: '94%',
                        border: 'none',
                        height: '94%',
                        padding: '0px 10px',
                        fontSize: 16
                    }}
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.title}
                        value={this.state.value}
                        onChange={e => {
                            this.setState({
                                value: e.target.value
                            })
                        }}
                    />
                </div>

            </div>
        )
    }
}

export { FloatInput }