const data = [

    {
        name: 'Home',
        cname: 'home',
        module: 'ORGANIZATIONS',
        path: '/app/home',
        link: '/app/home/organisation'
    },
    {
        name: 'Setting',
        cname: 'setting',
        module: 'SETTINGS',
        path: '/app/setting',
        link: '/app/setting/syncTask'
    },
    {
        name: 'Logout',
        cname: 'logout',
        module: 'LOGOUT',
        path: '/app/logout',
        link: '/app/logout',
        onClick: () => {
            window.localStorage.clear();
            window.location.reload('/')
        }
    }
]

export default data;