import React,{useState,useEffect} from 'react';
import { post,get } from '../../../../Model/Network/Config/Axios';
import { Button } from '../../../Common/Button';
import { FloatInput } from '../../../Common/FloatInput';
import { Modal } from '../../../Common/Modal/Modal';
import { PageLoader } from '../../../Common/PageLoader';
import { Table } from '../../../Common/Tables';
import { showToast } from '../../../Common/Toast/ToastCallback';
import { TopBackground } from '../../../Common/TopHeader/TopBackground';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';


const AddCity = () => {

    const [loading,setLoading] = useState(true);
    const [modal,setModal] = useState(false);
    const [city,setCity] = useState([]);

    let cityName= null,stateName=null,countryName=null,latValue=null,longValue=null;



    useEffect(()=>{
        init();
    },[])


    let init = () => {
        get(`misc/cities`,(e,r)=>{
            if(r) {
                setCity(r.cities)
                setLoading(false);
            }
        })
    }


    let mapItem = (item,index) => {
        return(
            <div key={index} className="table-item">
                <div style ={{
                    flex:1
                }}>
                    {item.name}
                </div>
                <div style ={{
                    flex:1
                }}>
                    {item.state}
                </div>
            </div>
        )
    }



    let add = () => {
        let city = cityName ? cityName.getValue(): null;
        let state= stateName ? stateName.getValue():null;
        let country = countryName ? countryName.getValue():null;
        let lat  = latValue ? latValue.getValue():null;
        let lng = longValue ? longValue.getValue():null;

        if(!city) {
            showToast({
                type:'error',
                message:"Enter the city name"
            })
            return;
        }

        if(!state) {
            showToast({
                type:'error',
                message:"Enter the state name"
            })
            return
        }

        if(!country) {
            showToast({
                type:'error',
                message:'Enter the country name'
            })
            return
        }

        if(!lat) {
            showToast({
                type:'error',
                message:"Enter the lat value"
            })
            return
        }

        if(!lng) {
            showToast({
                type:'error',
                message:'Enter the long value'
            })
            return
        }

        let data = {

            name:city,
            country,
            state,
            lat:+lat,
            lng:+lng,

        }

        post(`misc/city.add`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"City added successfullys"
                })
                setModal(false)
            } else {
                showToast({
                    type:'error',
                    message:e.data.error
                })
            }
        })

    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <div style={{
            height: 'calc( 100vh - 55px )',
            overflowY: 'scroll'
        }}>
            <TopHeader
                title="All City"
                desc="List of all citites"
            />
            <TopBackground>
                <div style ={{
                    position:'absolute',
                    right:20,
                    top:10
                }}>
                    <Button text="Add City" onClick={()=>{
                        setModal(true);
                    }} />
                </div>
            </TopBackground>

            <div className="page-container">
                <Table
                    data={city}
                    header={[
                        {
                            name:'City',
                            flex:1
                        },
                        {
                            name:'State',
                            flex:1
                        }
                       
                    ]}
                    mapItem={mapItem}

                />
            </div>


            {
                modal ?
                <Modal 
                    show={modal}
                    title="Add City"
                    desc="Fill up the detials and add city"
                    onClose={()=>{
                        setModal(false)
                    }}
                >

                    <FloatInput title="City Name" type="text" ref={ref=>cityName=ref}  />

                    <FloatInput title="State" type="text" ref={ref=>stateName=ref} />

                    <FloatInput title="Country" ref={ref=>countryName=ref} />

                    <FloatInput title="Lat" ref={ref=>latValue=ref} />

                    <FloatInput title="Long" ref={ref=>longValue=ref} />


                    <Button text="Add" onClick={add} style={{
                        marginTop:20
                    }} />


                </Modal>
                : null
            }

        </div>
    )
}

export default AddCity;