import React from 'react';
import { FloatInput } from '../../../Common/FloatInput';
import { Modal } from '../../../Common/Modal/Modal';
import { Button } from '../../../Common/Button';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';
import { post } from '../../../../Model/Network/Config/Axios';
import { FloatSetter } from '../../../../Utils/FloatSetter';
import { PageLoader } from '../../../Common/PageLoader';
import { Table } from '../../../Common/Tables';
import Model from './Model';
import { TopBackground } from '../../../Common/TopHeader/TopBackground';
import { showToast, handleError } from '../../../Common/Toast/ToastCallback';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.Model = new Model();
        this.state = {
            modal: false,
            organisation: [],
            loading: true,
            buttonLoading: false
        }
    }



    componentDidMount() {
        this.init();
    }


    init = () => {
        this.Model.init((e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    loading: false,
                    organisation: r.organizationDetails
                })
            }
        })
    }


    mapItem = (item, index) => {

        let onClick = () => {
            this.props.history.push(`/app/home/organisation/project/${item.id}`)
        }
        return (
            <div key={index} className="table-item" onClick={onClick}>
                <div style={{
                    flex: 1,
                    fontSize: 15
                }}>
                    <p style={{
                        fontWeight: 600,
                        color: 'var(--text-color)'

                    }}>{item.name}</p>
                </div>

                <div style={{
                    flex: 1
                }}>
                    <p style={{
                        fontSize: 14,
                        color: 'var(--text-color)'
                    }}>{item.email}</p>
                </div>

                <div className="table-right" style={{
                    width: 200
                }}>
                    <p className="hoverable">View</p>
                </div>
            </div>
        )
    }

    createOrganisation = () => {

        let name = this.org.getValue();
        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter the organisation name'
            })
            return;
        }
        let email = this.email.getValue();
        if (!email) {
            showToast({
                type: 'error',
                message: "Please enter the email address"
            })
            return;
        }
        let mobile = this.mobile.getValue();
        if (!mobile) {
            showToast({
                type: 'error',
                message: 'Please enter the mobile number'
            })
            return;
        }

        let adminName = this.admin.getValue();
        if (!adminName) {
            showToast({
                type: 'error',
                message: 'Please enter the admin name'
            })
            return;
        }

        let pan = this.pan.getValue();
        if (!pan) {
            showToast({
                type: 'error',
                message: "Please enter the pan number"
            })
            return;
        }

        let employeeCount = +this.employee.getValue();
        if (!employeeCount) {
            showToast({
                type: 'error',
                message: "Please enter the employee count"
            })
            return;
        }

        let projectCount = +this.project.getValue();
        if (!projectCount) {
            showToast({
                type: 'error',
                message: 'Please enter the project count'
            })
            return;
        }

        let wbsCount = +this.wbs.getValue();
        if (!wbsCount) {
            showToast({
                type: 'error',
                message: "Please enter the wbs count"
            })
            return;
        }

        let taskCount = +this.task.getValue();
        if (!taskCount) {
            showToast({
                type: 'error',
                message: "Please enter the task count"
            })
            return
        }
        let logo = ""

        this.setState({
            ...this.state,
            buttonLoading: true
        })

        let data = {
            name,
            email,
            mobile,
            adminName,
            logo,
            pan,
            wbsCount,
            taskCount,
            employeeCount,
            projectCount
        }

        post(`organization/create`, data, (e, r) => {
            if (r) {
                FloatSetter(this.org, this.email, this.mobile, this.adminName, this.pan, this.employee, this.project, this.wbs);
                this.setState({
                    modal: false,
                    buttonLoading: false
                })

                this.init();

            }
            if (e) {
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
                handleError(e);
            }
        })
    }


    render() {

        if (this.state.loading) {
            return <PageLoader />
        }
        return (
            <div style={{
                height: 'calc( 100vh - 55px )',
                overflowY: 'scroll'
            }}>
                <TopHeader
                    title="Organisation"
                    desc="Fill up the details and add organisation"
                />


                <TopBackground>
                    <div style={{
                        position: 'absolute',
                        right: 20,
                        top: 15,

                    }}>
                        <Button
                            text="Add Organisation"
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    modal: true
                                })
                            }}
                        />
                    </div>
                </TopBackground>


                <div className="page-container">

                    <Table
                        header={[
                            {
                                name: 'Name',
                                flex: 1
                            },
                            {
                                name: 'Email',
                                flex: 1
                            },
                            {
                                name: '',
                                width: 200
                            }
                        ]}
                        data={this.state.organisation}
                        mapItem={this.mapItem}
                    />


                </div>

                <Modal
                    title="Add organisation"
                    desc="Fill up the details and add admin"
                    show={this.state.modal}
                    onClose={() => {
                        this.setState({
                            ...this.state,
                            modal: false
                        })
                    }}
                >

                    <FloatInput type="text" title="Organization Name" handleRef={ref => this.org = ref} />
                    <FloatInput type="text" title="Admin Name" handleRef={ref => this.admin = ref} />
                    <FloatInput type="email" title="Email" handleRef={ref => this.email = ref} />
                    <FloatInput type="number" title="Mobile Number" handleRef={ref => this.mobile = ref} />
                    <FloatInput type="text" title="Pan Number" handleRef={ref => this.pan = ref} />
                    <FloatInput type="number" title="Project Count" handleRef={ref => this.project = ref} />

                    <FloatInput type="number" title="Employee Count" handleRef={ref => this.employee = ref} />
                    <FloatInput type="number" title="Wbs Count" handleRef={ref => this.wbs = ref} />
                    <FloatInput type="number" title="Task Count" handleRef={ref => this.task = ref} />



                    <Button style={{
                        marginTop: 30,
                        height: 40,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                        isLoading={this.state.buttonLoading}
                        text="Add"
                        onClick={this.createOrganisation}
                    />
                </Modal>
            </div>
        )
    }
}

export default Index;