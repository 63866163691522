import React from 'react';
import ReactDOM from 'react-dom';

let modalRoot = document.getElementById('modal-root');


class Portal extends React.Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }


    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }



    render() {
        return (
            ReactDOM.createPortal(
                this.props.children,
                this.el
            )
        )
    }
}

export { Portal }