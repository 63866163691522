import { get } from "../../Model/Network/Config/Axios"
import { AuthActions } from "../Reducers"

export function configController() {
    return function (dispatch) {
        get('auth/config', (e, r) => {
            if (r) {
                dispatch({
                    type: AuthActions.Config,
                    data: r
                })
            }
        })
    }
}