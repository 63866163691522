import React from 'react';
import Props from 'prop-types';
import Left from './leftArrow.png';
import Right from './rightArrow.png';

const COUNT = 10;

class Table extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }


    mapHeader = (item, index) => {
        let common = {
            fontWeight: 600,
            color: 'var(--text-color)',
            paddingBottom: 5,
            fontSize: 14,
            ...item.style
        }

        if (item.width) {
            common = {
                ...common,
                width: item.width
            }
        } else if (item.flex) {
            common = {
                ...common,
                flex: item.flex
            }
        } else {
            common = {
                ...common,
                flex: 1
            }
        }

        return (
            <div key={index} style={common}>
                {item.name}
            </div>
        )
    }


    render() {

        let start = this.state.page * COUNT + 1;
        let end = (this.state.page + 1) * COUNT

        if (end > this.props.data.length) {
            end = this.props.data.length;
        }
        if (this.props.pagination) {

        }
        return (
            <div style={{
                width: '100%',
                marginTop: 30,
                marginBottom: 30
            }} className="table">

                {/* Header */}

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                    borderBottom: '1px solid var(--border-color)',

                }}>
                    {this.props.header.map(this.mapHeader)}
                </div>

                {/* Body */}

                <div className="table-body">
                    {this.props.data.map(this.props.mapItem)}
                </div>

                {/* Footer */}

                <div className="table-footer">
                    <div style={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center'
                    }}>

                        <p style={{
                            fontSize: 16,
                            color: 'var(--text-color)'
                        }}>
                            <span style={{
                                fontWeight: 600,
                                paddingRight: 5
                            }}>{start}</span>
                            -
                            <span style={{
                                fontWeight: 600,
                                paddingLeft: 5,
                                paddingRight: 5
                            }}>{end}</span>
                             of
                            <span style={{
                                fontWeight: 600,
                                paddingLeft: 5,
                                paddingRight: 5
                            }}>{this.props.data.length}</span>
                        </p>
                        <div style={{
                            display: 'flex',
                            border: '1px solid var(--border-color)',
                            height: 30,
                            width: 60,
                            alignItems: 'center',
                        }}>
                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                height: 30,
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }} onClick={() => {
                                if (this.state.page > 0) {
                                    this.setState({
                                        ...this.state,
                                        page: this.state.page - 1
                                    })
                                }
                            }}>
                                <img src={Left} alt="" style={{
                                    height: 14,
                                    width: 14,
                                }} />
                            </div>
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }} onClick={() => {

                            }}>
                                <img src={Right} alt="" style={{
                                    height: 14,
                                    width: 14
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}


Table.propsTypes = {
    header: Props.array,
    data: Props.array,
    mapItem: Props.func,
    pagination: Props.bool
}

Table.defaultProps = {
    header: [],
    data: [],
    mapItem: () => { },
    pagination: true
}

export { Table }