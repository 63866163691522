import React from 'react';
import { Button } from '../Button';
import { arrow } from '../Images';



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.onClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClick)
    }

    onClick = (e) => {
        if (this.state.active && !this.profile.contains(e.target)) {
            this.setState({
                ...this.state,
                active: false
            })
        }
    }
    render() {
        let { data } = this.props;
        return (
            <div className="profile" ref={ref => this.profile = ref}>
                <div style={{
                    height: 35,
                    width: 35,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...this.state.active ?
                        {
                            backgroundColor: 'rgba(92, 103, 138, .1)'

                        } : {
                        }
                }}>
                    <div onClick={() => {
                        this.setState({
                            active: !this.state.active
                        })

                    }}
                        style={{
                            cursor: 'pointer',

                        }}
                        className={`sidebar-image ${this.state.active ? 'active' : 'inactive'}`} />
                </div>

                {this.state.active &&
                    <div style={{
                        position: 'absolute',
                        top: 55,
                        right: 0,
                        zIndex: 2,
                        backgroundColor: '#fff',
                        height: 200,
                        width: 300,
                        border: '1px solid var(--border-color)',
                        boxShadow: '0 2px 6px rgb(0 0 0 / 18%)',
                        padding: 15,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{
                            borderBottom: '1px dashed var(--border-color)',
                            paddingBottom: 10
                        }}>
                            <h2 style={{
                                fontSize: 14,
                                textTransform: 'uppercase',
                                color: 'var(--text-color)'
                            }}>{data.name}</h2>
                            <p style={{
                                fontSize: 12,
                                color: 'var(--text-light-color)'
                            }}>{data.email}</p>
                        </div>

                        <div style={{
                            paddingTop: 10,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }} onClick={() => {
                            this.props.history.push('/app/password/reset');
                            this.setState({
                                ...this.state,
                                active: false
                            })
                        }}>

                            <p style={{
                                fontWeight: 600,
                                color: 'var(--button-color)',
                                fontSize: 12,
                            }}>Reset Password</p>

                            <img src={arrow} alt="" style={{
                                height: 10,
                                width: 10,
                                marginLeft: 5
                            }} />


                        </div>



                        <Button style={{
                            marginTop: 'auto'
                        }}
                            text="Logout"
                        />
                    </div>}
            </div>
        )
    }
}

export default Index;