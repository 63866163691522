import React from 'react';
import {Route,Switch,useLocation,useParams} from 'react-router-dom'
import { TopHeader } from '../../../Common/TopHeader/TopHeader';


/* Pages */

import Site from './Site';
import Employees from './Employees';
import Setting from './Setting';


const ProjectDetails = () => {


    let {id} = useParams();
    let {pathname} = useLocation();

    return(
        <>
          <TopHeader
             title="Project Details"
             desc="All the project details"
             items={
                 [
                    {
                        name:'All Site',
                        path:`/app/home/organisation/project/${id}`,
                        active:pathname === `/app/home/organisation/project/${id}`
                    },
                    {
                        name:'Employee',
                        path:`/app/home/organisation/project/${id}/employees`,
                        active:pathname === `/app/home/organisation/project/${id}/employees`
          
                    },
                    {
                        name:'Setting',
                        path:`/app/home/organisation/project/${id}/setting`,
                        active:pathname === `/app/home/organisation/project/${id}/setting`
          
                    }
                 ]
             }
          />
          <div style ={{height:'calc( 100vh - 150px )',overflowY:'auto'}}>
              <Switch>
                 <Route path="/app/home/organisation/project/:id" exact component={Site} />
                 <Route path="/app/home/organisation/project/:id/employees" component={Employees} exact/>
                 <Route path="/app/home/organisation/project/:id/setting" component={Setting} exact/>

              </Switch>
          </div>
         
        </>
    )
}


export default ProjectDetails