import React from 'react';
import Props from 'prop-types';


/* Common Buttom component used across project */

const Button = ({ text, onClick, style, isLoading }) => {


    /* isLoading is used whenever we make api call and make button disable with some loading effect  */

    if (isLoading) {
        return (
            <div style={{
                backgroundColor: 'var(--button-color)',
                padding: "9px 15px",
                textAlign: 'center',
                color: '#fff',
                fontWeight: 700,
                borderRadius: 2,
                cursor: 'pointer',
                fontSize: 13,
                border: '1px solid var(--button-color)',
                minWidth: 100,
                boxShadow: '0 1px 0 0 rgb(171 181 189 / 50%), 0 3px 6px 0 rgb(0 0 0 / 20%)',
                height: 40,
                boxSizing: 'border-box',
                ...style
            }}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }


    return (
        <div style={{
            backgroundColor: 'var(--button-color)',
            padding: "9px 15px",
            textAlign: 'center',
            color: '#fff',
            fontWeight: 700,
            borderRadius: 2,
            cursor: 'pointer',
            fontSize: 13,
            border: '1px solid var(--button-color)',
            minWidth: 100,
            boxShadow: '0 1px 0 0 rgb(171 181 189 / 50%), 0 3px 6px 0 rgb(0 0 0 / 20%)',
            height: 40,
            boxSizing: 'border-box',
            ...style
        }} onClick={onClick} className="button">
            {text}
        </div>
    )
}

Button.propsTypes = {
    text: Props.string.isRequired,
    onClick: Props.func.isRequired,
    style: Props.func
}

Button.defaultProps = {
    isLoading: false
}

export { Button };