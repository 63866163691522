import React, { useEffect,useState } from 'react';
import {useParams} from 'react-router-dom';
import Moment from 'moment';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { Table } from '../../../../Common/Tables';
import { TopBackground } from '../../../../Common/TopHeader/TopBackground';


const Index = () => {

    const [project,setProject] = useState([])
    const [loading,setLoading] = useState(true);

    const {id} = useParams();



    useEffect(()=>{
        get(`organization/allProjects/${id}`,(e,r)=>{
            if(r) {
                setProject(r.allProjects);
                setLoading(false);
            }
        })
    },[id])


   let mapItem = (item, index) => {
        return (
            <div className="table-item" key={index}>
                <div style={{
                    flex: 2
                }}>
                    <p style={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: 'var(--text-color)'
                    }}>{item.name}</p>
                    <p style={{
                        fontSize: 11,
                        color: 'var(--text-light-color)'
                    }}>{item.address}</p>
               </div>
               <div style={{flex:1}}>
                     <p style={{
                      fontSize:13
                    }}>{Moment(item.startDate).format('DD-MM-YYYY')}</p>
             
               </div>
                <div style={{
                    flex: 1,
                    fontSize: 13
                }}>
                    {item.city}
                </div>
                <div style={{
                    flex: 1,
                    fontSize: 13
                }}>
                    <p>INR {item.budget}</p>
                </div>
                <div className="table-right" style={{
                    width: 100
                }}>

                </div>
            </div>
        )
    }




    if(loading) {
        return(
            <PageLoader />
        )
    }

    return(
       <div>
           <TopBackground
           />
           
           <div className="page-container">
                <Table
                    mapItem={mapItem}
                    data={project}
                    header={[
                        {
                            name: 'Details',
                            flex: 2
                        },
                        {
                            name:'Start Date',
                            flex:1
                        },
                        {
                            name: 'City',
                            flex: 1,
                        },
                        {
                            name: 'Budget',
                            flex: 1
                        },
                        {
                            name: '',
                            width: 100
                        }
                    ]}
                />
           </div>
       </div>
    )
}

export default Index;