import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Login';
import AuthenticatedController from './AuthenticatedController';
import storeData from '../Redux/index';


const store = createStore(storeData, applyMiddleware(thunk));

const Index = () => {

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={Login} exact />
                    <Route path="/app" component={AuthenticatedController} />
                </Switch>
            </BrowserRouter>
        </Provider>
    )
}

export default Index;