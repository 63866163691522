import React from 'react';
import Props from 'prop-types';
import { Portal } from '../Portal/Portal';
import close from './close.png';


class Modal extends React.Component {


    render() {
        return (
            <Portal>
                {
                    this.props.show ?
                        <div style={{
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            width: '100%',
                            overflowY: 'auto'
                        }} >

                            <div style={{
                                backgroundColor: '#fff',
                                minHeight: 150,
                                margin: '0px auto',
                                marginTop: 50,
                                padding: 20,
                                width: 500,
                                borderRadius: 2,

                            }} >

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 30
                                }}>
                                    <div>
                                        <h1 style={{
                                            fontSize: 20,
                                            color: "var(--text-color)"
                                        }}>{this.props.title}</h1>
                                        <p style={{
                                            fontSize: 12,
                                            color: 'var(--text-color)'
                                        }}>{this.props.desc}</p>
                                    </div>

                                    <img src={close} alt="" onClick={() => {
                                        this.props.onClose();
                                    }} style={{
                                        height: 16,
                                        width: 16,
                                        cursor: "pointer",
                                        marginTop: 5
                                    }} />
                                </div>
                                <div style={{

                                }}>
                                    {this.props.children}

                                </div>
                            </div>
                        </div>
                        : null
                }
            </Portal>
        )
    }
}

Modal.propsTypes = {
    title: Props.string,
    desc: Props.string,
    show: Props.bool
}

export { Modal };