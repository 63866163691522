import React from 'react';
import Input from '../Common/Input';
import { post } from '../../Model/Network/Config/Axios';



class Forgot extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            success: false
        }
    }


    resetLink = () => {
        let username = this.email.getValue();

        if (this.state.success) {

            let otp = this.otp.getValue();
            let password = this.pass.getValue();
            let confirm = this.cpass.getValue();

            if (password !== confirm) {
                return;
            }

            let data = {
                username,
                otp,
                password,
                confirm
            }

            post(`auth/otp`, data, (e, r) => {
                if (r) {
                    this.props.changeScreen()
                }
            })

        } else {


            post(`auth/otp`, { username }, (e, r) => {
                if (r) {
                    this.setState({
                        ...this.state,
                        success: true
                    })
                }
            })
        }
    }


    render() {
        return (
            <div style={{
                width: '40%',
                backgroundColor: 'var(--background-color)',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'auto',
                justifyContent: "center"
            }}>

                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <img src="" alt="" />
                    <div style={{
                        width: '65%'
                    }}>

                        <p style={{
                            fontSize: 34,
                            color: 'var(--text-color)',
                        }}>Forgot Password</p>

                        <p style={{
                            color: 'var(--text-light-color)',
                            fontWeight: 400,
                            marginTop: 2,
                            fontSize: 12
                        }}>Manage your something here</p>

                        <div style={{
                            marginTop: 130
                        }}>
                            <Input
                                title="Enter Email"
                                type="email"
                                handleRef={ref => {
                                    this.email = ref
                                }}
                            />

                            {
                                this.state.success ?
                                    <div>
                                        <Input
                                            title="Enter Otp"
                                            type="text"
                                            handleRef={ref => {
                                                this.otp = ref
                                            }}
                                        />

                                        <Input
                                            title="Password"
                                            type="password"
                                            handleRef={ref => {
                                                this.pass = ref
                                            }}
                                        />
                                        <Input
                                            title="Confirm Password"
                                            type="password"
                                            handleRef={ref => {
                                                this.cpass = ref
                                            }}
                                        />

                                    </div> : null
                            }

                            <p style={{
                                textAlign: 'right',
                                width: '100%',
                                color: 'var(--text-light-color)',
                                fontWeight: 500,
                                marginTop: 30,
                                cursor: 'pointer',
                                marginLeft: 20,
                                fontSize: 14
                            }} onClick={this.props.changeScreen}>Already have credentials ?</p>

                            <div style={{
                                width: '100%',
                                backgroundColor: 'var(--button-color)',
                                color: '#fff',
                                fontWeight: 600,
                                height: 42,
                                cursor: 'pointer',
                                marginTop: 50,
                                fontSize: 14,
                                boxShadow: '2px 5px 8px -5px rgba(0,0,0,0.63)',
                                padding: '0px 10px'
                            }} className="login-button flex-center" onClick={this.resetLink} >
                                Send Reset Link
                        </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Forgot;