import React, { useState } from 'react';
import { post } from '../../../../Model/Network/Config/Axios';
import { Button } from '../../../Common/Button';
import { TopBackground } from '../../../Common/TopHeader/TopBackground';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';


const Index = () => {


    const [buttonLoading, setButtonLoading] = useState(false);

    let onClick = () => {
        setButtonLoading(true);

        post(`misc/syncTaskChecklist`, {}, (e, r) => {
            if (r) {
                alert('Task Sync successfully')
            }
            setButtonLoading(false);
        })

    }

    return (
        <div style={{
            height: 'calc( 100vh - 55px )',
            overflowY: 'scroll'
        }}>
            <TopHeader
                title="Sync"
                desc="Sync all task in the organisation"
            />
            <TopBackground />

            <div className="page-container" >
                <Button
                    text="Sync"

                    isLoading={buttonLoading}
                    onClick={onClick}
                />
            </div>
        </div>
    )
}

export default Index;