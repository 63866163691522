import React from 'react';
import Profile from './Profile';
import { connect } from 'react-redux';

class Index extends React.Component {


    render() {
        return (
            <div className="header">

                <div style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{
                        fontWeight: 700,
                        fontSize: 13,
                        color: 'var(--text-color)',
                        paddingRight: 5,
                    }}>{this.props.config.name}</p>
                    <Profile {...this.props} data={this.props.config} />
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config.config
    }
}
const Header = connect(mapStateToProps, null)(Index)
export { Header }