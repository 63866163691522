import { get } from "../../Utils/Crypto"
// import * as Crypto from "../../Utils/Crypto";

const initalState = {
    authenticated: false
}

const AuthActions = {
    Login: "Auth_Login",
    Change: "Auth_Office",
    Logout: "Auth_Logout",
    Config: "Auth_Config"
}


const initialStore = () => {
    const loggedIn = get('loggedin');

    //Crypto.set("loggedin", "no");

    if (loggedIn && loggedIn === 'yes') {
        return {
            ...initalState,
            authenticated: true,
            name: get('name')
        }
    } else {


        return {
            ...initalState,
            authenticated: false
        }
    }
}



export const config = (state = initialStore(), action) => {
    let data = action.data;


    switch (action.type) {

        case AuthActions.Login:
            return {
                ...state,
                authenticated: true,
                name: data.name,
                lastLogin: data.login
            }
        case AuthActions.Config:
            return {
                ...initialStore(),
                config: data
            }
        default:
            return state;
    }


}



export { AuthActions };