import React from 'react';
import { TopBackground } from '../../../../Common/TopHeader/TopBackground';



const Setting = () => {

    return(
        <>
          <TopBackground />
          <div className="page-container">

            <p>Disable Organisation</p>

            <div className="btn btn-delete">Disable Organisation</div>
          </div>
        </>
    )
}


export default Setting;