const parseJson = data => {
	let parse = data.data;
	let lines = parse.split("\n");
	let newData = [];
	let headers =[];
	for(var i =0;i<lines.length-1;i++){
		if(i===0){
			headers = mapHeaders(lines[i]);
		}else{
			let line = lines[i];
			let split = line.split("|");
			let item = {};
			for(var a = 0;a<split.length;a++){
				let lineData = split[a];
				let header = headers[a];
				item[header.key] = getValue(header.type,lineData);
			}
			newData.push(item);
		}
	}
	data.data = newData;
	return data;
}

const getValue = (header,value) => {
	if(header==="number"){
		return  parseInt(value,10);
	}else if(header==="double"){
		return parseFloat(value,10);
	}else if(header==="bool"){
		return value === "true";
	}else if(header==="owner"){
		let owner = {};
		let values = value.split(">");
		if(values.length<4){
			owner.ownerId = values[0];
			owner.ownerType = values[1];
			owner.ownerName = values[2];
		}else{
			owner.officeName = values[0];
			owner.departmentName = values[1];
			owner.gradeName = values[2];
			owner.ownerEmail = values[3];
			owner.ownerId = values[4];
			owner.ownerType = values[5];
			owner.ownerName = values[6];
		}
		return owner;
	}
	return value;
}

const mapHeaders = (header) => {
	let headers = [];
	let allHeaders = header.split('|');
	for(var i =0;i<allHeaders.length;i++){
		let item = allHeaders[i];
		let split = item.split("@");
		headers.push({
			key:split[0],
			type:split[1]
		})
	}
	return headers;
}

export {
	parseJson
}
