import React from 'react';
import Model from './Model';
import Input from '../Common/Input/index';
import { connect } from 'react-redux';
import { attempLogin } from '../../Redux/Actions/Login';
import Forgot from './Forgot';
import './index.css';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.Model = new Model();
        this.state = {
            forgot: false
        }

    }


    login = () => {
        let email = this.email.getValue();
        if (!email) {
            return;
        }

        let password = this.password.getValue();
        if (!password) {
            return;
        }

        this.props.login({ email, password });
    }


    render() {

        if (this.props.config.authenticated) {
            this.props.history.push('/app/home/organisation')
        }
        return (
            <div style={{
                display: 'flex',
                flex: 1,
                height: '100vh',
                boxSizing: 'content-box',
            }} onKeyPress={e => {
                if (e.key === 'Enter') {
                    this.login()
                }
            }}>


                {
                    this.state.forgot ?
                        <Forgot changeScreen={() => {
                            this.setState({
                                ...this.state,
                                forgot: false
                            })
                        }}


                        /> :


                        <div style={{
                            width: '40%',
                            backgroundColor: 'var(--background-color)',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowY: 'auto',
                            justifyContent: "center"

                        }}>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>

                                <img src="" alt="" />
                                <div style={{
                                    width: '65%'
                                }}>

                                    <p style={{
                                        fontSize: 36,
                                        color: 'var(--text-color)'
                                    }}>Login to <b style={{
                                        fontWeight: 800,
                                        letterSpacing: 0.5,

                                    }}>BuildRun</b></p>

                                    <p style={{
                                        color: 'var(--text-light-color)',
                                        fontWeight: 400,
                                        marginTop: 2,
                                        fontSize: 12
                                    }}>Manage your something here</p>

                                    <div style={{
                                        marginTop: 130
                                    }}>
                                        <Input
                                            title="Enter Email"
                                            type="email"
                                            handleRef={ref => {
                                                this.email = ref
                                            }}
                                        />

                                        <Input
                                            title="Enter Password"
                                            type="password"
                                            handleRef={ref => {
                                                this.password = ref
                                            }}
                                        />


                                    </div>

                                    <p style={{
                                        textAlign: 'right',
                                        width: '100%',
                                        color: 'var(--text-light-color)',
                                        fontWeight: 500,
                                        marginTop: 30,
                                        cursor: 'pointer',
                                        marginLeft: 20,
                                        fontSize: 14
                                    }} onClick={() => {
                                        this.setState({
                                            forgot: true
                                        })
                                    }}>Forgot Your Password ?</p>


                                    <div style={{
                                        width: '100%',
                                        backgroundColor: 'var(--button-color)',
                                        color: '#fff',
                                        fontWeight: 600,
                                        height: 42,
                                        cursor: 'pointer',
                                        marginTop: 50,
                                        fontSize: 14,
                                        boxShadow: '2px 5px 8px -5px rgba(0,0,0,0.63)',
                                        padding: '0px 10px'
                                    }} className="login-button flex-center" onClick={this.login} >
                                        Login
                        </div>



                                </div>
                            </div>
                        </div>
                }

                <div style={{
                    width: '60%',
                    backgroundImage: 'linear-gradient(to right bottom, #525b7a, #5b5f81, #656388, #70668e, #7b6a93)'
                }}>

                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}


const mapDisptachToProps = (dispatch) => {
    return {
        login: (obj) => { dispatch(attempLogin(obj)) }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(Login);