import React from 'react'
import Spinner from "react-spinner-material";

class PageLoader extends React.Component {

    render() {
        return (
            <div style={{
                flex: 1,
                width: '100%',
                padding: 30
            }}>
                <div style={{
                    margin: '0 auto',
                    background: "#fff",
                    borderRadius: 25,
                    height: 30,
                    width: 30,
                    border: '1px solid #dedede',
                    padding: 7,
                    boxShadow: "1px 2px 1px rgba(1,1,1,.2)",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Spinner radius={28} visible={true} color={`#404959`} />
                </div>
            </div>
        )
    }

}

export {
    PageLoader
}
