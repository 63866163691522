import React from 'react';
import {useHistory} from 'react-router-dom';

const TopHeader = ({ title, desc ,items,...props}) => {

    const history = useHistory();

    console.log(history);

    let mapItem = (item,index) => {
        return(
            <div key={index} style ={{
                height:30,
                display:'flex',
                alignItems:'center',
                marginRight:20,
                marginTop:10,
                cursor:'pointer',
                ...item.active ?
                {
                    color:"var(--text-color)",
                    fontWeight:700,
                    borderBottom:'2px solid var(--text-color)',
                    paddingBottom:5
                }:
                {

                }
            }} onClick={()=>{
                if(props.onClick) {
                    props.onClick()
                } else {
                    console.log(item.path)
                    history.push(item.path)
                }
            }}>
                {item.name}
            </div>
        )
    }


    return (
        <div style={{
            minHeight: 50,
            backgroundColor: '#fff',
            borderBottom: '1px solid var(--border-color)',
            padding: '10px 20px 0px'
        }}>
            <h1 style={{
                fontSize: 22,
                fontWeight: 800,
                color: 'var(--text-color)'
            }}>{title}</h1>
            <p style={{
                fontSize: 12,
                color: 'var(--text-light-color)',
                marginTop: 2
            }}>{desc}</p>


            <div style={{display:'flex'}}>
                {items.map(mapItem)}
            </div>  
        </div>
    )
}


TopHeader.defaultProps = {
    items:[]
}
export { TopHeader }