import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Reset from './Reset';
import { MiniSideBar } from '../../Common/Sidebar/miniSidebar';


class Index extends React.Component {


    render() {
        return (
            <div style={{
                flex: 1,
                display: 'flex'
            }}>
                <MiniSideBar
                    {...this.props}
                    title="Password"
                    items={
                        [
                            {
                                name: 'Reset Password',
                                path: '/app/password/reset'
                            }
                        ]}

                />
                <div style={{
                    flex: 1
                }}>
                    <Switch>
                        <Route path="/app/password/reset" component={Reset} exact />
                    </Switch>
                </div>
            </div>
        )
    }
}


export default Index;