import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MiniSideBar } from '../../Common/Sidebar/miniSidebar';
import SyncTask from './SyncTask';
import AddCity from './AddCity';


class Index extends React.Component {


    render() {
        return (
            <div style={{
                flex: 1,
                display: 'flex'
            }}>
                <MiniSideBar
                    {...this.props}
                    title="Settings"
                    items={
                        [
                            {
                                name: 'Sync Task',
                                path: '/app/setting/syncTask'
                            },
                            {
                                name:'Add City',
                                path:'/app/setting/city'
                            },
                            {
                                name: 'All settings',
                                path: '/app/setting/all'
                            }
                        ]}

                />
                <div style={{
                    flex: 1
                }}>
                    <Switch>
                        <Route path="/app/setting/syncTask" component={SyncTask} exact />
                        <Route path="/app/setting/city" component={AddCity} exact />
                    </Switch>
                </div>


            </div>
        )
    }
}

export default Index;