import { get } from "../../../../Model/Network/Config/Axios";


class Model {


    init = (cb) => {
        get(`organization/allOrganizations`, cb)
    }

}

export default Model;