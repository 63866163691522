import React from 'react'
import { addToastCallback, removeCallback } from "./ToastCallback";
import { v4 } from 'uuid'
import Toast from './Toast'

class ToastContainer extends React.Component {

	state = {
		toasts: []
	}

	componentDidMount() {
		addToastCallback(item => {
			item.id = v4()
			let toasts = this.state.toasts;
			toasts.push(item)
			this.setState({
				toasts
			})
		})
	}

	componentWillUnmount() {
		removeCallback()
	}

	onFilter = id => {
		let toasts = this.state.toasts.filter(item => item.id !== id);
		this.setState({
			toasts
		})
	}

	item = item => {
		return (<Toast key={item.id} data={item} close={this.onFilter} />)
	}

	render() {
		if (this.state.toasts.length > 0) {
			return (
				<div style={{
					position: 'fixed',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					background: 'rgba(1,1,1,.12)',
					padding: 50,
					zIndex: 111
				}}>
					{this.state.toasts.map(this.item)}
				</div>
			)
		}
		return (<div />)
	}

}

export {
	ToastContainer
}
