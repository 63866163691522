import React from 'react';


const TopBackground = ({ ...props }) => {
    return (
        <div style={{
            height: 65,
            position: 'relative'
        }}>
            {props.children}
        </div>
    )
}

export { TopBackground }