import React from 'react';
import data from './data';

class Sidebar extends React.Component {


    mapHeader = (item, index) => {

        let active = this.props.location.pathname.includes(item.path);
        return (
            <div key={index} style={{
                cursor: 'pointer',
                height: 42,
                width: 42,
                marginBottom: 15,
                ...active ? {
                    borderRadius: '50%',
                    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(92,103,138,1) 0%, rgba(133,142,173,1) 100%)'

                } : {

                    }

            }} onClick={() => {
                if (item.onClick) {
                    item.onClick();
                } else {
                    this.props.history.push(item.link);
                }

            }} className={`flex-center ${active ? 'sidebar-single-active' : 'sidebar-single-inactive'}`}>
                <div className={`sidebar-image ${item.cname}`} />
            </div>
        )
    }

    render() {
        return (
            <div className="sidebar">

                <div style={{
                    paddingTop: 20
                }} />
                {
                    data.map(this.mapHeader)
                }
            </div>
        )
    }
}

export { Sidebar };