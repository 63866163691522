import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* Pages */
import Organisation from './Organisation';
import OrganisationSetting from './OrganisationSetting';
import ProjectDetails from './ProjectDetials';


/*Common Component*/
import { MiniSideBar } from '../../Common/Sidebar/miniSidebar';



class Index extends React.Component {


    render() {
        return (
            <div style={{
                flex: 1,
                display: 'flex'
            }}>
                <MiniSideBar
                    {...this.props}
                    title="Home"
                    items={
                        [
                            {
                                name: 'Admin',
                                path: '/app/home/organisation'
                            }

                        ]
                    }
                />

                <div style={{
                    flex: 1
                }}>
                    <Switch>
                        <Route path="/app/home/organisation" component={Organisation} exact />
                        <Route path="/app/home/organisation/setting/:id" component={OrganisationSetting} exact />
                        <Route path="/app/home/organisation/project/:id" component={ProjectDetails} />
                    </Switch>
                </div>


            </div>
        )
    }
}

export default Index;