import React from 'react';
import Props from 'prop-types';


class MiniSideBar extends React.Component {


    mapItem = (item, index) => {
        let active = this.props.location.pathname.includes(item.path);
        return (
            <div key={index} style={{
                height: 40,
                borderBottom: '1px solid var(--border-color)',
                paddingLeft: 10,
                alignItems: 'center',
                display: 'flex',
                cursor: 'pointer',
                fontSize: 13,
                ...active ? {
                    fontWeight: 700,
                    color: "var(--active-color)",
                    borderRight: '3px solid var(--active-color)'

                } : {
                        color: 'var(--text-color)',
                        fontWeight: 600
                    }
            }} onClick={() => {
                this.props.history.push(item.path)
            }}>
                {item.name}
            </div>
        )
    }

    render() {
        return (
            <div style={{
                width: 200,
                borderRight: '1px solid var(--border-color)',
                height: '100vh',
                marginTop: -55,
                backgroundColor: '#fff'
            }}>
                <div style={{
                    height: 54,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 900,
                    fontSize: 10,
                    textTransform: 'uppercase',
                    marginLeft: 10,
                    letterSpacing: 1,
                    color: 'var(--text-light-color)'
                }}>
                    {this.props.title}
                </div>

                {this.props.items.map(this.mapItem)}
            </div>
        )
    }
}


MiniSideBar.propsTypes = {
    items: Props.array.isRequired
}

MiniSideBar.defaultProps = {
    items: []
}

export { MiniSideBar };