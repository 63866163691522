import React from 'react';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';
import { TopBackground } from '../../../Common/TopHeader/TopBackground';
import { FloatInput } from '../../../Common/FloatInput';
import { Button } from '../../../Common/Button';
import { post } from '../../../../Model/Network/Config/Axios';
import { FloatSetter } from '../../../../Utils/FloatSetter';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    changePassword = () => {
        let password = this.pass.getValue();
        if (!password) {
            return;
        }

        let confirm = this.cpass.getValue();
        if (!confirm) {
            return;
        }

        if (password !== confirm) {
            return;
        }

        let data = {
            password,
            confirm
        }

        post(`auth/reset`, data, (e, r) => {
            if (r) {
                FloatSetter(this.pass, this.cpass);

            }
        })
    }


    render() {
        return (
            <div style={{
                height: 'calc( 100vh - 55px)',
                overflowY: 'auto'
            }}>
                <TopHeader
                    title="Reset Password"
                    desc="Reset your password"
                />
                <TopBackground />
                <div className="page-container">
                    <FloatInput type="password" title="Password" handleRef={ref => this.pass = ref} />
                    <FloatInput type="password" title="Confirm Password" handleRef={ref => this.cpass = ref} />
                    <Button
                        text="Change Password"
                        style={{
                            marginTop: 30,
                            padding: '12px 20px'
                        }}
                        onClick={this.changePassword}
                    />
                </div>
            </div>
        )
    }
}

export default Index;