import React from 'react';
import Model from './Model';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';
import { TopBackground } from '../../../Common/TopHeader/TopBackground';
import { Button } from '../../../Common/Button';
import { Modal } from '../../../Common/Modal/Modal';



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.Model = new Model();
        this.state = {
            deleteModal: false
        }
    }

    getId = () => {
        return this.props.match.params.id;
    }


    render() {
        return (
            <div style={{
                height: 'calc( 100vh - 55px )',
                overflowY: 'scroll'
            }}>

                <TopHeader
                    title="Organisation Setting"
                    desc="Change your organisation setting here"
                />

                <TopBackground />

                <div className="page-container">

                    <h1 style={{
                        fontSize: 16,
                        color: 'var(--text-color)'
                    }}>Delete Organisation</h1>
                    <p style={{
                        fontSize: 12,
                        color: 'var(--text-light-color)'
                    }}>By click on below button you will delete organisation</p>

                    <Button
                        text="Delete"
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                deleteModal: true
                            })
                        }}
                        style={{
                            marginTop: 20,
                            width: 100
                        }}
                    />


                    {
                        this.state.deleteModal ?
                            <Modal
                                show={this.state.deleteModal}
                                title="Delete Organisation"
                                desc="By clicking the below button delete selected organisation"
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        deleteModal: false
                                    })
                                }}
                            >

                                <p>Are you sure want to delete this organisation ?</p>

                                <Button
                                    text="Delete Organisation"
                                    style={{
                                        marginTop: 20,
                                        backgroundColor: "var(--red-color)",
                                        borderColor: 'var(--red-color)'
                                    }}

                                    onClick={() => {
                                        this.Model.deleteOrg(this.getId(), (e, r) => {
                                            this.setState({
                                                deleteModal: false
                                            })
                                            this.props.history.push('/app/home/organisation');

                                        })
                                    }}
                                />

                            </Modal>
                            : null
                    }

                </div>

            </div>
        )
    }
}

export default Index;