import React,{useEffect,useState} from 'react';
import {useParams} from 'react-router-dom';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { Table } from '../../../../Common/Tables';
import { TopBackground } from '../../../../Common/TopHeader/TopBackground';



const Employees  = () => {

    const {id} = useParams();

    const [loading,setLoading] = useState(true);
    const [employees,setEmployee] = useState([])


    let mapItem = (item,index) => {
        return(
            <div className="table-item" style ={{flex:1}}>
                <div style ={{flex:1}}>
                    <p>{item.name}</p>
                    <p style ={{
                        fontSize:11,
                        color:'var(--text-light-color)'
                    }}>{item.email}</p>
                </div>
                <div style ={{flex:1}}>
                    {item.employeeCode}
                </div>
                <div className="table-right" style ={{width:200}}>
                    <p className="hoverable">View</p>
                </div>
            </div>
        )
    }

    useEffect(()=>{
        get(`organization/employee/all/${id}`,(e,r)=>{
            if(r) {
                setEmployee(r.employees);
                setLoading(false);
            }
        })
    },[id])

    
    if(loading) {
        return(
            <PageLoader />
        )
    }


    return(
        <>
            <TopBackground/>
            <div className="page-container">
                <Table
                   data={employees}
                   mapItem={mapItem}
                   header={[
                       {
                        name:'Details',
                        flex:1
                       },
                       {
                        name:'Code',
                        flex:1
                       },
                       {
                        name:'',
                        width:200
                       }
                   ]}
                />
            </div>
        </>
    )
}

export default Employees;